import { Backdrop, CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import { connect, useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import myapi from "./API/MyAPI";
import LocaleURL from "./LocaleURL";
import PWAInstallPrompt from "./PWAInstallPrompt";
import ErrorPage from "./Pages/404Error/404";
import PaymentStatus from "./Pages/Home/paymentResponsePages/paymentStatus";
import SubscribeSccusful from "./Pages/Home/paymentResponsePages/subscribe-succusful";
import AlertPopup from './components/AlerPopup';
import useBaramenMessanger from './hooks/useBaramenMessanger';
import Wrapper from "./i18n/Wrapper";
import { GetAllCategories } from "./redux/Actions/CategoryActions";
import { userLocale } from "./redux/Storage/MyStorage";
import { LOGIN_STATUS, USER_ROLES } from "./redux/Types/authTypes";
import { GET_USER_PROFILE } from "./redux/Types/userTypes";

const TRACKING_ID = "G-ZJLNZC4JFS"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App(props) {

  const dispatch = useDispatch();
  const location = useLocation();
  const baramenSocket = useBaramenMessanger()

  const queryParams = new URLSearchParams(location.search);
  const localeState = useSelector(state => state.locale)
  if (userLocale() === null) {
    userLocale('en')
  }

  useEffect(() => {
    baramenSocket.InitConnection()

    myapi.get(`/User/GetMyProfile`).then(
      result => {
        if (result.data.statusCode === 200) {
          let res = result.data
          dispatch({
            type: GET_USER_PROFILE,
            payload: res.data.profile
          })
          dispatch({
            type: USER_ROLES,
            payload: res.data.roles
          })
          dispatch({
            type: LOGIN_STATUS,
            payload: true
          })
        }
      }
    )
    props.GetAllCategories()

    ReactGA._gaCommandSendPageview(window.location.pathname + window.location.search);
  }, []);

  const theme = createTheme({
    direction: localeState.dir,
    components: {
      MuiAutocomplete: {
        defaultProps: {
          fullWidth: true
        }
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
          size: 'small'
        }
      },
      MuiButton: {

        defaultProps: {
          color: 'primary',
          variant: 'outlined',
          size: 'small'
        },
        styleOverrides: {
          text: {
            padding: 0
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: (themeParam) => `
        input:disabled {
          color: black;
          -webkit-text-fill-color: inherit !important
        }
        textarea:disabled {
          color: black;
          -webkit-text-fill-color: inherit !important
        }
      `
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: 'red' },
          root: {
            paddingBlock: "8px",
            color: 'black',
            fontWeight: 700
          }
        }
      }
    },
    typography: {
      label: {
        fontWeight: 700
      },
      fontFamily: localeState.dir === "rtl" ? 'IranSans' : "Helvetica",
      button: {
        textTransform: 'none',
        color: 'white'
      },
      a: {
        color: 'white'
      },
      h1: {
        fontSize: 24,
        fontWeight: 700
      },
      h2: {
        fontSize: 18,
        fontWeight: 700
      }
    },
    palette: {
      primary: {
        main: '#1c39bb',
      },
      secondary: {
        main: "#f3d24f"
      }
    },
    shape: {
      borderRadius: 4
    },
  });

  function isIos() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  function isInStandaloneMode() {
    return ('standalone' in window.navigator) && (window.navigator.standalone);
  }

  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (isIos() && !isInStandaloneMode()) {
      setShowPrompt(true);
    }
  }, []);

  useEffect(() => {
    const lang = userLocale()

    console.log(lang)
    if (lang === 'en') {
      const i = "fDqr6w";
      const a = window;
      const d = document;

      var script = document.querySelector(`script[src="https://www.goftino.com/widget/lUhiYn"]`);
      if (script) {
        script.remove();
      }
      script = document.querySelector(`script[src="https://www.goftino.com/widget/fDqr6w"]`);
      if (script) {
        script.remove();
      }
      
      function g() {
        const g = d.createElement("script");
        const s = "https://www.goftino.com/widget/" + i;
        const l = localStorage.getItem("goftino_" + i);
        g.async = true;
        g.src = l ? s + "?o=" + l : s;
        d.getElementsByTagName("head")[0].appendChild(g);
      }

      if (d.readyState === "complete") {
        g();
      } else if (a.attachEvent) {
        a.attachEvent("onload", g);
      } else {
        a.addEventListener("load", g, false);
      }
    }
    else {
      const i = "lUhiYn";
      const a = window;
      const d = document;

      var script = document.querySelector(`script[src="https://www.goftino.com/widget/fDqr6w"]`);
      if (script) {
        script.remove();
      }
      script = document.querySelector(`script[src="https://www.goftino.com/widget/lUhiYn"]`);
      if (script) {
        script.remove();
      }

      function g() {
        const g = d.createElement("script");
        const s = "https://www.goftino.com/widget/" + i;
        const l = localStorage.getItem("goftino_" + i);
        g.async = true;
        g.src = l ? s + "?o=" + l : s;
        d.getElementsByTagName("head")[0].appendChild(g);
      }

      if (d.readyState === "complete") {
        g();
      } else if (a.attachEvent) {
        a.attachEvent("onload", g);
      } else {
        a.addEventListener("load", g, false);
      }
    }
  }, [userLocale()]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Wrapper>

          <Backdrop open={showPrompt} id="pwaisntall" sx={{ backgroundColor: 'white', zIndex: 9999 }}>
            <PWAInstallPrompt onClose={e => setShowPrompt(false)} />
          </Backdrop>

          <AlertPopup sx={{ fontFamily: theme.typography.fontFamily, direction: localeState.dir }} />
          <Routes>
            <Route exact path="/x22" element={<Navigate to={`/fa/about-company?ref=122`} />} />
            <Route exact path="/ref123" element={<Navigate to={`/fa`} />} />
            <Route exact path="/ref/:id" element={<LocaleURL />} />
            <Route
              path="/successful-subscription"
              element={<SubscribeSccusful />}
            />
            <Route path="/paymentStatus" element={<PaymentStatus />} />
            <Route path="/errorPage" element={<ErrorPage />} />
            <Route path="/en/*" element={<LocaleURL locale='en' />}></Route>
            <Route path="/fa/*" element={<LocaleURL locale='fa' />}></Route>
            <Route path="/*" element={<LocaleURL />}></Route>
          </Routes>
        </Wrapper>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

const mapStateToProps = (state) => ({ auth: state.auth, locale: state.locale });
export default connect(mapStateToProps, { GetAllCategories })(App);

export const softShadow = "0px 4px 60px 0px rgba(0,0,0,0.07)"; // Custom shadow used in design
