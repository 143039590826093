import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { useDispatch, useSelector } from "react-redux";
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { setCurrency, setLocale, setMessages } from '../redux/Slices/LocaleSlice';
import { userLocale } from '../redux/Storage/MyStorage';

// Create rtl cache
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

function RTL(props) {
    return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
}

export const LanguageContext = React.createContext({});

const locale = userLocale();


function Wrapper(props) {

    const localeState = useSelector(state => state.locale)
    const dispatch = useDispatch()

    const fetchMessages = (language) => {
        console.log(language)
        fetch(`/languages/${language}.json`)
            .then(response => response.json())
            .then(json => {
                dispatch(setMessages(json));
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
            });
    };

    useEffect(() => {
        const lang = userLocale()
        dispatch(setLocale(lang))
        fetchMessages(lang)
    }, [])

    useEffect(() => {
        document.dir = localeState.dir
    }, [localeState.dir])

    const selectLanguage = (e) => {
        const newLocale = e.target.value;
        if (newLocale == 'fa')
            dispatch(setCurrency('Toman'))

        dispatch(setLocale(newLocale))

        fetchMessages(newLocale)
    }

    return (
        <LanguageContext.Provider value={{ _locale: localeState.language, selectLanguage }}>
            <IntlProvider defaultLocale={window.location.hostname.includes(".ir") ? "fa" : 'en'} messages={localeState.messages} locale={localeState.language}>
                {
                    localeState.dir === 'rtl' ?
                        <RTL>
                            {props.children}
                        </RTL>
                        :
                        props.children
                }
            </IntlProvider>
        </LanguageContext.Provider>

    );
}

export default Wrapper;