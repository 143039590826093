import AddBoxIcon from '@mui/icons-material/AddBox';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import I18n from './i18n/i18n';

export default function PWAInstallPrompt(props) {
    return (
        <Grid container item xs={10}>
            <Grid item xs={12} textAlign={'center'} marginBottom={2}>
                <img src="/logo.png" height={"128px"} />
            </Grid>
            <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={'24px'} marginBlock={1}>
                    <I18n id='pwa.install.title' />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent sx={{ '& p': { fontSize: '18px' } }}>
                        <Typography component={'p'} marginBlock={0.5}>
                            <I18n id="pwa.step.1" iosshare={e => <IosShareIcon />} />
                        </Typography>
                        <Typography component={'p'} marginBlock={0.5}>
                            <I18n id="pwa.step.2" iosadd={e => <AddBoxIcon />} />
                        </Typography>
                        <Typography component={'p'} marginBlock={0.5}>
                            <I18n id="pwa.step.3" />
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button fullWidth variant='contained' sx={{ backgroundColor: '#1c39bb', fontSize:'18px' }} onClick={e => props.onClose()}>
                            <I18n id="pwa.step.okbutton" />
                        </Button>
                    </CardActions>
                </Card>

            </Grid>
        </Grid>
    )
}
